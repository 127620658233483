// src/components/Header.js
import React from 'react';
import { NavLink} from 'react-router-dom'
import manvec from '../assets/manvec.png'
import treadmill from '../assets/treadmill.jpg'
import { MoveRight } from 'lucide-react'
// import wavehaikei from '../assets/wavehaikei.svg'
import { Check, Instagram } from 'lucide-react';

const HeroHeader = () => {
  const handleCalendlyPopup = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/jordanstaffordcoaching' });
    return false; // Prevent default action
  };
  return (
    <header className="heroheader py-24">
      <div class="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">

        <div className='herotext px-6 md:px-0 text-left justify-center'>
          <div class="split-content home-hero-content zebra pt-0 sm:pt-12 lg:pt-0">
              <div class="subtitle text-blue-700 text-xl whitespace-nowrap mb-4 md:mb-8">JORDAN STAFFORD, COACH</div>
              <h1  class="title heroh1 home-hero text-5xl md:text-6xl mb-4 md:mb-8">Reach Your Potential</h1>
              <p  class="paragraph home-hero mb-8">
                Transform your fitness journey with personalized coaching. 
                Whether you're just starting out or looking to take your training further, 
                with my tailored guidance and support to reach your goals you will be unstoppable!
              </p>
            
            <div  class="md:flex flex-col md:flex-row items-center md:space-x-8">
              <a href="#sessions" class="mb-2 md:mb-0 transition duration-200 ease-in-out border border-black hover:border-blue-700 text-blCK hover:bg-blue-700 flex hover:text-white text-md tester px-6 py-4 rounded-full">
                BOOK A CLASS
              </a>
              <a onClick={handleCalendlyPopup} class="mb-2 md:mb-0 transition duration-200 ease-in-out border border-black hover:border-blue-700 bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 rounded-full">
                BOOK A CALL
              </a>
              <a href="#programs" class="mb-8 md:mb-0 transition duration-200 ease-in-out border border-black hover:border-blue-700 text-blCK hover:bg-blue-700 flex hover:text-white text-md tester px-6 py-4 rounded-full">
                PROGRAMS
              </a>
            </div>

          </div>
        </div>

        <img className='heroimage px-8 md:px-0' src={treadmill}></img>

      </div>       

      <div id='sessions' className='pt-20 md:pt-20 px-6 '>
        {/* <h2 className='text-5xl mb-16'>Programs</h2> */}
        <div className='max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 place-content-center'>

          <div className='programimage w-full bg-grayback testbg py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div class="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div className='flex flex-col items-center'>
              <h2 class="text-2xl mb-2">1 PT Session</h2>
              <div class="text-4xl ">£35</div>
              {/* <p class="text-xl text-gray-500 mb-6">
                Get race-ready in just 8 weeks with my comprehensive Hyrox Prep Program!
              </p> */}
              </div>
              <div>
              {/* <div class="mb-6">
                <div class="text-xl mb-4">What's included:</div>

                <div class="w-layout-grid plan-item-features-grid">
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>8 Week Program</div>
                  </div>
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Recipe Book</div>
                  </div>
        
                </div>

              </div> */}
              <a href="https://buy.stripe.com/14k5lS8dYguL9tmaEE" target="_blank" class="justify-center bg-black hover:bg-blue-700 mt-10 flex text-white text-md tester px-6 py-4 rounded-full">
                BUY
              </a>
              </div>
            </div>
          </div>

          <div className='programimage w-full bg-grayback testbg py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div class="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div className='flex flex-col items-center'>
                <h2 class="text-2xl text-center mb-2">5 PT Session Block</h2>
                <div class="text-4xl">£150</div>
                {/* <p class="text-xl text-gray-500 mb-6">Build muscle<br/> Increase Strength<br/> Improve Cardio</p> */}
              </div>              
              <div>
              {/* <div class="mb-6">
                <div class="text-xl mb-4">What's included:</div>
                <div class="w-layout-grid plan-item-features-grid">
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>12 Week Program</div>
                  </div>
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Recipe Book</div>
                  </div>
                  
                  
                </div>
              </div> */}
              <a href="https://buy.stripe.com/9AQ9C8dyidizgVO8wx" target="_blank" class="justify-center bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 mt-10 rounded-full">
                BUY
              </a>
              </div>
            </div>
          </div>
          
          <div className='programimage w-full bg-grayback testbg py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div className="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div className='flex flex-col items-center'>
              <h2 class="text-2xl text-center mb-2">10 PT Session Block</h2>
              <div class="text-4xl">£280</div>
              {/* <p class="text-xl text-gray-500 mb-6">
                Get race-ready in just 8 weeks with my comprehensive Hyrox Prep Program!
              </p> */}
              </div>
              <div>
              {/* <div class="mb-6">
                <div class="text-xl mb-4">What's included:</div>

                <div class="w-layout-grid plan-item-features-grid">
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>8 Week Program</div>
                  </div>
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Recipe Book</div>
                  </div>
                  
                </div>

              </div> */}
              <a href="https://buy.stripe.com/8wMg0w0Lwbar7le8wy" target="_blank" class="justify-center bg-black hover:bg-blue-700 mt-10 flex text-white text-md tester px-6 py-4 rounded-full">
                BUY
              </a>
              </div>
            </div>
          </div>
        </div>

      </div> 
    </header>


  );
};

export default HeroHeader;
