// src/components/Header.js
import React, {useState}  from 'react';
import { NavLink} from 'react-router-dom';
import { MoveRight, Home, LayoutDashboard, Calendar, LineChart, Plus, Star, Menu, X, Instagram} from 'lucide-react';
import { PopupButton } from 'react-calendly';

const Header = () => {
  const handleCalendlyPopup = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/jordanstaffordcoaching' });
    return false; // Prevent default action
  };
  const [mobileSidebarVisible, setMobileSidebarVisible] = useState(false);
  const toggleMobileSidebar = () => {
    setMobileSidebarVisible(!mobileSidebarVisible);
  };

  return (
    <> 
    <div className={`fixed absolute top-4 left-5 z-50 lg:hidden`}>
              {!mobileSidebarVisible && (
                <h1 className='text-black tester font-bold text-2xl'>JORDAN STAFFORD</h1>
              ) }
            </div>
    <div className={`fixed absolute top-2 right-4 z-50 lg:hidden`}>
              {!mobileSidebarVisible && (
                  <button
                      onClick={toggleMobileSidebar}
                      className="py-2 text-greyishtext"
                  >
                      <Menu size={32} />
                  </button>
              ) }
            </div>
    <header className="border-b w-full border-gray-100 hidden lg:flex  mx-auto">
      <div className="max-w-7xl w-full mx-auto flex justify-between items-center text-black bg-white py-4">
        <div className="text-white text-xl font-bold text-gray-400">
          <h1 className='text-black tester text-2xl'>JORDAN STAFFORD</h1>
        </div>
        <div className='flex justify-center items-center'>
        <nav className='mr-8'>
          <ul className="flex space-x-6 tester">
            <li>
              <NavLink to="/" className="text-md hover:text-blue-700">HOME</NavLink>
            </li>
            <li>
              <a href="#about" className="text-md hover:text-blue-700">ABOUT</a>
            </li>
            <li>
              <a href="#coaching" className="text-md hover:text-blue-700">COACHING</a>
            </li>
            <li>
              <a href="#programs" className="text-md hover:text-blue-700">PROGRAMS</a>
            </li>
            <li>
              <a href="#faq" className="text-md hover:text-blue-700">FAQ</a>
            </li>
            <li className='flex flex-col justify-center'>
              <a href="https://www.instagram.com/jordan_stafford92/" target='_blank' className="text-md hover:text-blue-700 "><Instagram size={20} /></a>
            </li>
          </ul>
        </nav>
        {/* <a href="#contact" className=" bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 rounded-full">
            BOOK A CALL
            <span className="bg-white rounded ml-2 px-1"><MoveRight /></span>
          </a> */}

          <button
            onClick={handleCalendlyPopup}
            className="bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 rounded-full cursor-pointer transition duration-200 ease-in-out"
          >
            BOOK A CALL
          </button>
          
        </div>
      </div>
    </header>

    {/* Mobile Sidebar */}
    {mobileSidebarVisible && (
      <div className="fixed absolute top-0 inset-0 bg-grayback z-40 flex flex-col items-center text-white w-screen">
          <div className="flex justify-between items-center w-full p-4">
              <button
                  onClick={toggleMobileSidebar}
                  className="p-2 text-greyishtext"
              >
                  <X size={32} color='currentColor' className="text-black" />
              </button>
          </div>
          <div className="text-white text-xl font-bold text-gray-400">
            <h1 className='text-black tester text-2xl mb-6 md:mb-0'>JORDAN STAFFO</h1>
          </div>
          <nav className='md:mr-8'>
          <ul className="flex-col md:flex-row md:space-x-6 space-y-5 tester text-black">
            <li>
              <NavLink to="/" className="text-md hover:text-blue-700">HOME</NavLink>
            </li>
            <li>
              <a href="about" onClick={toggleMobileSidebar} className="text-md hover:text-blue-700">ABOUT</a>
            </li>
            {/* <li>
              <a href="#skills" onClick={toggleMobileSidebar} className="text-md hover:text-blue-700">ONLINE COACHING</a>
            </li> */}
            <li>
              <a href="#programs" onClick={toggleMobileSidebar} className="text-md hover:text-blue-700">PROGRAMS</a>
            </li>
            <li>
              <a href="#faq" onClick={toggleMobileSidebar} className="text-md hover:text-blue-700">FAQ</a>
            </li>
            <li className='flex flex-col justify-center items-center'>
              <a href="https://www.instagram.com/jordan_stafford92/" className="text-md hover:text-blue-700 "><Instagram size={20} /></a>
            </li>
          </ul>
        </nav>
          {/* <nav className="flex flex-col w-3/4 items-center space-y-4 mt-4"> */}

              {/* <SidebarItem 
                  icon={<LayoutDashboard size={20} />} 
                  text="Dashboard" 
                  to="/dashboard"
                  isActive={activeItem === "Dashboard"}
                  setActive={setActiveItem} 
                  mobileSidebarVisible={mobileSidebarVisible}
                  toggleMobileSidebar={toggleMobileSidebar}
                  className="w-full mb-0"
                  />
              <SidebarItem 
                  icon={<LineChart size={20} />} 
                  text="Strategies" 
                  to="/strategies" 
                  isActive={activeItem === "Strategies"}
                  setActive={setActiveItem}
                  mobileSidebarVisible={mobileSidebarVisible}
                  toggleMobileSidebar={toggleMobileSidebar}
                  className="w-full"
                  />
              <SidebarItem 
                  icon={<Settings size={20} />} 
                  text="Settings"
                  isActive={activeItem === "Settings"}
                  setActive={setActiveItem}
                  to='/settings' 
                  mobileSidebarVisible={mobileSidebarVisible}
                  toggleMobileSidebar={toggleMobileSidebar}
                  className="w-full"
                  />
              <SidebarItem icon={<LogOut size={20} />} text="Logout" onClick={handleLogout} className="w-full"/> */}
          {/* </nav> */}
      </div>
  )}
  </>   
  );
};

export default Header;
